<template>
  <b-overlay :show="show">
    <div class="my-register-page" style="font-size: 13px">
      <div class="my-container">
        <!-- <b-row class="mb-2">
          <b-col>
            <h1>{{ $t("inspectionbookofcontractor") }}</h1>
          </b-col>
        </b-row> -->

        <!-- <b-table-simple bordered responsive="sm">
          <b-tbody>
            <b-tr variant="primary">
              <b-td colspan="4" class="text-center">
                <span style="font-size: 18px; font-weight: bold">{{
                  $t("DocumentInfo")
                }}</span>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td style="font-weight:bold">
                {{ $t("inn") }}
              </b-td>
              <b-td>
                {{ Request.contractor.inn }}
              </b-td>
              <b-td style="font-weight:bold">
                {{ $t("name") }}
              </b-td>
              <b-td>
                {{ Request.contractor.fullName }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td style="font-weight:bold">
                {{ $t("oblast") }}
              </b-td>
              <b-td>
                {{ Request.contractor.region }}
              </b-td>
              <b-td style="font-weight:bold">
                {{ $t("region") }}
              </b-td>
              <b-td>
                {{ Request.contractor.district }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td style="font-weight:bold">
                {{ $t("oked") }}
              </b-td>
              <b-td colspan="3">
                {{ Request.contractor.oked }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td style="font-weight:bold">
                {{ $t("orderedOrganization") }}
              </b-td>
              <b-td>
                {{ Request.orderedOrganization }}
              </b-td>
              <b-td style="font-weight:bold">
                {{ $t("inspectionOrganization") }}
              </b-td>
              <b-td>
                {{ Request.inspectionOrganization }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td style="font-weight:bold">
                {{ $t("authorizedOrganization") }}
              </b-td>
              <b-td colspan="3">
                {{ Request.authorizedOrganization }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple> -->
        <div class="cardStyleEdit mb-3 mt-4 pt-2">
          <b-row>
            <b-col>
              <h5 class="styleText">1 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="6">
              <custom-input
                disabled
                :placeholder="$t('orderCode')"
                v-model="Request.orderCode"
                :label="$t('orderCode')"
              ></custom-input>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">2 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <!-- <b-table-simple bordered responsive>
            <b-tbody>
              <b-tr variant="primary">
                <b-td colspan="4" class="text-center">
                  <span style="font-size: 18px; font-weight: bold">{{
                    $t("DocumentInfo")
                  }}</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("inn") }}
                </b-td>
                <b-td>
                  {{ orgInfo.contractor.innOrPinfl }}
                </b-td>
                <b-td style="font-weight:bold">
                  {{ $t("name") }}
                </b-td>
                <b-td>
                  {{ orgInfo.contractor.fullName }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("oblast") }}
                </b-td>
                <b-td>
                  {{ orgInfo.contractor.region }}
                </b-td>
                <b-td style="font-weight:bold">
                  {{ $t("region") }}
                </b-td>
                <b-td>
                  {{ orgInfo.contractor.district }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("oked") }}
                </b-td>
                <b-td >
                  {{ orgInfo.contractor.oked }}
                </b-td>
                 <b-td style="font-weight:bold">
                  {{ $t("fullname") }}
                </b-td>
                <b-td>
                  {{ orgInfo.fullName }}
                </b-td>
              </b-tr>             
            </b-tbody>
          </b-table-simple> -->
          <b-row class="mt-3">
            <b-col cols="12" sm="6" md="6" class="mb-3">
              <v-select
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :placeholder="$t('orderedOrganization')"
                class="my-select"
                :options="ParentList"
                :reduce="(item) => item.value"
                label="text"
                v-model="Request.orderedOrganizationId"
                @input="ChangeOrganization"
              ></v-select>
            </b-col>
            <b-col cols="12" sm="6" md="6" class="mb-3">
              <v-select
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :placeholder="$t('inspectionOrganization')"
                class="my-select"
                :options="OrganizationList"
                :reduce="(item) => item.value"
                label="text"
                v-model="Request.inspectionOrganizationId"
                @input="ChangeInspectionOrganization"
              ></v-select>
            </b-col>
            <b-col cols="12" sm="12" md="6">
              <custom-input-white
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :placeholder="$t('inspectionOrganizationAddres')"
                v-model="Request.contractorAddres"
                :label="$t('inspectionOrganizationAddres')"
              ></custom-input-white>
            </b-col>
            <b-col cols="12" sm="12" md="6">
              <custom-input-white
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :placeholder="$t('inspectionOrganizationName')"
                v-model="Request.inspectionOrganizationName"
                :label="$t('inspectionOrganizationName')"
              ></custom-input-white>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="6">
              <custom-input-white
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :placeholder="$t('inspectionOrganizationPostalAddres')"
                v-model="Request.inspectionOrganizationAddres"
                :label="$t('inspectionOrganizationPostalAddres')"
              ></custom-input-white>
            </b-col>
            <b-col cols="12" sm="12" md="6">
              <custom-input-white
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :placeholder="$t('inspectionOrganizationCeo')"
                v-model="Request.inspectionOrganizationCeo"
                :label="$t('inspectionOrganizationCeo')"
              ></custom-input-white>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col class="mt-2 mb-2" style="text-align: left">
              <h5 class="styleText">3 - {{ $t("punkt") }}</h5>
            </b-col>
            <b-col
              v-if="
                !(
                  $route.query.contractor == true ||
                  $route.query.contractor == 'true'
                )
              "
              class="mt-2 mb-2"
              style="text-align: right"
            >
              <b-button
                v-if="
                  Request.statusId != 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                @click="AddNewInspectors"
                style="width: 150px"
                variant="outline-primary"
              >
                {{ $t("Add") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="6" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("inspectors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("employeeName") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyNumberInspector1") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyExpirationInspector") }}
                      </span>
                    </b-td>
                    <b-td style="min-width:200px !important">
                      <span style="font-size: 14px; font-weight: bold;">
                        {{ $t("certificateNumber1") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateExpiration1") }}
                      </span>
                    </b-td>

                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("actions") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr
                    style="background-color: white"
                    v-for="(item, index) in RequestTable"
                    :key="index"
                  >
                    <b-td>
                      {{ item.fullName }}
                    </b-td>
                    <b-td>
                      <custom-input-white
                        :label="$t('')"
                        :disabled="Request.statusId == 2"
                        :placeholder="$t('dutyNumberInspector1')"
                        v-model="item.dutyNumber"
                      ></custom-input-white>
                    </b-td>
                    <b-td>
                      <custom-date-picker
                        :disabled="Request.statusId == 2"
                        :label="$t('dutyExpirationInspector')"
                        placeholder="00.00.0000"
                        v-model="item.dutyExpiration"
                      ></custom-date-picker>
                    </b-td>
                    <b-td>
                      <!-- {{ item.certificateNumber }} -->
                      <custom-input-white
                        :label="$t('')"
                        :disabled="Request.statusId == 2"
                        :placeholder="$t('certificateNumber1')"
                        v-model="item.certificateNumber"
                      ></custom-input-white>
                    </b-td>
                    <b-td>
                      <!-- {{ item.certificateExpiration }} -->
                      <custom-date-picker
                        :disabled="Request.statusId == 2"
                        :label="$t('certificateExpiration1')"
                        placeholder="00.00.0000"
                        v-model="item.certificateExpiration"
                      ></custom-date-picker>
                    </b-td>

                    <b-td class="text-center">
                      <!-- {{ item.dutyExpiration }} -->
                      <b-link
                        v-if="
                          !(
                            $route.query.contractor == true ||
                            $route.query.contractor == 'true'
                          )
                        "
                        @click="DeleteInspector(item, index)"
                      >
                        <b-icon font-scale="1.5" icon="trash"></b-icon>
                      </b-link>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col class="mt-2 mb-2" style="text-align: left">
              <h5 class="styleText">4 - {{ $t("punkt") }}</h5>
            </b-col>
            <b-col
              v-if="
                !(
                  $route.query.contractor == true ||
                  $route.query.contractor == 'true'
                )
              "
              class="mt-2 mb-2"
              style="text-align: right"
            >
              <b-button
                v-if="
                  Request.statusId != 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                @click="AddInspectors"
                style="width: 150px"
                variant="outline-primary"
              >
                {{ $t("Add") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="10" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("involvedInspectors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("organizationName") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("addressEmail") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("inspectorFullName") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyNumberInspector") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyExpirationInspector") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyIssuingOrganization") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateNumber1") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateIssuingOrganization") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("actions") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr
                    style="background-color: white"
                    v-for="(item, index) in Request.involvedInspectors"
                    :key="index"
                  >
                    <b-td>
                      {{ item.organizationName }}
                    </b-td>

                    <b-td>
                      {{ item.address }}
                    </b-td>
                    <b-td>
                      {{ item.inspectorFullName }}
                    </b-td>

                    <b-td>
                      {{ item.dutyNumber }}
                    </b-td>
                    <b-td>
                      {{ item.dutyExpiration }}
                    </b-td>

                    <b-td>
                      {{ item.dutyIssuingOrganization }}
                    </b-td>
                    <b-td>
                      {{ item.certificateNumber }}
                    </b-td>

                    <b-td>
                      {{ item.certificateExpiration }}
                    </b-td>
                    <b-td>
                      {{ item.certificateIssuingOrganization }}
                    </b-td>
                    <b-td class="text-center">
                      <!-- {{ item.dutyExpiration }} -->
                      <b-link
                        v-if="
                          !(
                            $route.query.contractor == true ||
                            $route.query.contractor == 'true'
                          )
                        "
                        @click="EditInvolvedInspector(item)"
                      >
                        <b-icon font-scale="1.2" icon="pencil"></b-icon>
                      </b-link>
                      <b-link
                        v-if="
                          !(
                            $route.query.contractor == true ||
                            $route.query.contractor == 'true'
                          )
                        "
                        @click="DeleteInvolvedInspector(item, index)"
                      >
                        <b-icon font-scale="1.2" icon="trash"></b-icon>
                      </b-link>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="
                !(
                  $route.query.contractor == true ||
                  $route.query.contractor == 'true'
                )
              "
              class="mt-2 mb-2"
              style="text-align: right"
            >
              <b-button
                v-if="
                  Request.statusId != 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                @click="AddAuditors"
                style="width: 150px"
                variant="outline-primary"
              >
                {{ $t("Add") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="8" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("InvolvedAuditors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("organizationNameAuditor") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseDate") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("auditorFullName") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("actions") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr
                    style="background-color: white"
                    v-for="(item, index) in Request.involvedAuditors"
                    :key="index"
                  >
                    <b-td>
                      {{ item.organizationName }}
                    </b-td>

                    <b-td>
                      {{ item.licenseDate }}
                    </b-td>
                    <b-td>
                      {{ item.licenseNumber }}
                    </b-td>
                    <b-td>
                      {{ item.licenseExpiration }}
                    </b-td>
                    <b-td>
                      {{ item.auditorFullName }}
                    </b-td>
                    <b-td>
                      {{ item.contractNumber }}
                    </b-td>
                    <b-td>
                      {{ item.contractExpiration }}
                    </b-td>
                    <b-td class="text-center">
                      <!-- {{ item.dutyExpiration }} -->
                      <b-link
                        v-if="
                          !(
                            $route.query.contractor == true ||
                            $route.query.contractor == 'true'
                          )
                        "
                        @click="EditAuditors(item)"
                        class="m-1"
                      >
                        <b-icon font-scale="1.2" icon="pencil"></b-icon>
                      </b-link>
                      <b-link
                        v-if="
                          !(
                            $route.query.contractor == true ||
                            $route.query.contractor == 'true'
                          )
                        "
                        @click="DeleteAuditors(item, index)"
                      >
                        <b-icon font-scale="1.2" icon="trash"></b-icon>
                      </b-link>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="
                !(
                  $route.query.contractor == true ||
                  $route.query.contractor == 'true'
                )
              "
              class="mt-2 mb-2"
              style="text-align: right"
            >
              <b-button
                v-if="
                  Request.statusId != 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                @click="AddSpecialists"
                style="width: 150px"
                variant="outline-primary"
              >
                {{ $t("Add") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="5" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("specialists")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("inspectorsName") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractNumber1") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("identificationDocument") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("actions") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr
                    style="background-color: white"
                    v-for="(item, index) in Request.specialists"
                    :key="index"
                  >
                    <b-td>
                      {{ item.fullName }}
                    </b-td>

                    <b-td>
                      {{ item.contractNumber }}
                    </b-td>
                    <b-td>
                      {{ item.identificationDocument }}
                    </b-td>

                    <b-td>
                      {{ item.contractExpiration }}
                    </b-td>
                    <b-td class="text-center">
                      <!-- {{ item.dutyExpiration }} -->
                      <b-link
                        v-if="
                          !(
                            $route.query.contractor == true ||
                            $route.query.contractor == 'true'
                          )
                        "
                        @click="EditSpecialists(item)"
                      >
                        <b-icon font-scale="1.2" icon="pencil"></b-icon>
                      </b-link>
                      <b-link
                        v-if="
                          !(
                            $route.query.contractor == true ||
                            $route.query.contractor == 'true'
                          )
                        "
                        @click="DeleteSpecialists(item, index)"
                      >
                        <b-icon font-scale="1.2" icon="trash"></b-icon>
                      </b-link>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">5 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="4" class="mb-3">
              <custom-input-white
                class="inputStyle"
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                v-mask="'###-###-###'"
                :placeholder="$t('docNumber5')"
                v-model="Request.docNumber"
                :label="$t('docNumber5')"
              ></custom-input-white>
            </b-col>
            <b-col cols="12" sm="12" md="4" class="mb-3">
              <custom-input-white
                class="inputStyle"
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :placeholder="$t('orderNumber5')"
                v-model="Request.orderNumber"
                :label="$t('orderNumber5')"
              ></custom-input-white>
            </b-col>
            <b-col cols="12" sm="12" md="4" class="mb-3">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :label="$t('orderDate5')"
                placeholder="00.00.0000"
                v-model="Request.orderDate"
              ></custom-date-picker>
            </b-col>
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                class="inputStyle"
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :placeholder="$t('criminalCaseInfo')"
                v-model="Request.criminalCaseInfo"
                :label="$t('criminalCaseInfo')"
              ></custom-textarea>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">6 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="12">
              <v-select
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                multiple
                :placeholder="$t('controlFunctions')"
                class="my-select"
                :options="ControlFunctionList"
                :reduce="(item) => item.value"
                label="text"
                v-model="Request.controlFunctions"
                @input="ControlFunctionsChange"
              ></v-select>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">7 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <!-- <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :label="$t('docDate')"
                placeholder="00.00.0000"
                v-model="Request.docDate"
              ></custom-date-picker>
            </b-col> -->

            <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :label="$t('startDate')"
                placeholder="00.00.0000"
                v-model="Request.startDate"
              ></custom-date-picker>
            </b-col>

            <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :label="$t('endDate')"
                placeholder="00.00.0000"
                v-model="Request.endDate"
              ></custom-date-picker>
            </b-col>

            <b-col cols="12" sm="12" md="4">
              <custom-input-white
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                placeholder="0"
                v-model="Request.checkDaysNumber"
                :label="$t('checkDaysNumber')"
              ></custom-input-white>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">8 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <!-- <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                :label="$t('docDate')"
                placeholder="00.00.0000"
                v-model="Request.docDate"
              ></custom-date-picker>
            </b-col> -->

            <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                :label="$t('contractorStartDate')"
                placeholder="00.00.0000"
                v-model="Request.contractorStartDate"
              ></custom-date-picker>
            </b-col>

            <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                :label="$t('contractorEndDate')"
                placeholder="00.00.0000"
                v-model="Request.contractorEndDate"
              ></custom-date-picker>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">9 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="4">
              <custom-input-white
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                placeholder="0"
                v-model="Request.contractorCheckDaysNumber"
                :label="$t('ContractorCheckDaysNumber')"
              ></custom-input-white>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">10 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                :placeholder="$t('requestedDocuments')"
                v-model="Request.requestedDocuments"
                :label="$t('requestedDocuments')"
              ></custom-textarea>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">11 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                :placeholder="$t('takenMeasures')"
                v-model="Request.takenMeasures"
                :label="$t('takenMeasures')"
              ></custom-textarea>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">12 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                :placeholder="$t('thirdPartyInfo')"
                v-model="Request.thirdPartyInfo"
                :label="$t('thirdPartyInfo')"
              ></custom-textarea>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">{{ $t("comment") }}</h5>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                :placeholder="$t('comment')"
                v-model="Request.comment"
                :label="$t('comment')"
              ></custom-textarea>
            </b-col>
            <b-col class="mt-2" cols="12" sm="12" md="4">
              <b-form-rating
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                v-model="Request.rate"
                variant="warning"
              ></b-form-rating>
            </b-col>
          </b-row>
        </div>
        <b-row class="mb-3">
          <b-col col="6">
            <b-button
              @click="back"
              style="width: 150px"
              variant="outline-danger"
            >
              {{ $t("back") }}
            </b-button>
          </b-col>
          <b-col col="6" style="text-align: right">
            <b-button
              v-if="Request.statusId != 2"
              @click="SaveData"
              style="width: 150px"
              variant="outline-success"
            >
              {{ $t("save") }}
            </b-button>
          </b-col>
        </b-row>
        <b-modal
          v-model="modalInspectorsInfo"
          hide-footer
          size="lg"
          no-close-on-backdrop
          hide-header
          centered
        >
          <div
            style="
              text-align: right;
              margin-right: 10px;
              margin-top: -5px;
              margin-bottom: 5px;
              border-bottom: 1px solid lightgray;
            "
          >
            <span
              @click="modalInspectorsInfo = false"
              style="cursor: pointer; font-size: 30px"
            >
              &times;
            </span>
          </div>
          <div class="mb-2">
            <custom-input
              :placeholder="$t('search')"
              v-model="filter1.search"
              :label="$t('search')"
              @keyup.enter="AddNewInspectors"
              @input="AddNewInspectors"
            ></custom-input>
          </div>
          <b-table :items="items" bordered :fields="fields">
            <template #cell(isSelected)="{ item }">
              <div class="text-center">
                <b-form-checkbox v-model="item.isSelected"> </b-form-checkbox>
              </div>
            </template>
          </b-table>

          <div>
            <b-pagination
              v-model="filter1.page"
              :total-rows="filter1.totalRows"
              :per-page="filter1.pageSize"
              first-number
              last-number
              @input="AddNewInspectors"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <b-icon icon="chevron-double-left" size="18" />
              </template>
              <template #next-text>
                <b-icon icon="chevron-double-right" size="18" />
              </template>
            </b-pagination>
          </div>
          <div style="text-align: right">
            <b-button
              variant="outline-success"
              :disabled="isSave"
              @click="AddInspectorsTableInfo"
            >
              <b-spinner
                style="margin-left: 20px"
                v-if="isSave"
                small
              ></b-spinner>
              {{ $t("save") }}
            </b-button>
          </div>
        </b-modal>
        <b-modal
          size="lg"
          v-model="modal"
          hide-footer
          hide-header
          no-close-on-backdrop
        >
          <b-row>
            <b-col>
              <h5>{{ $t("Add") }}</h5>
            </b-col>
          </b-row>
          <b-row class="backColor">
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                :placeholder="$t('inspectorsName')"
                v-model="tabrow.fullName"
                :label="$t('inspectorsName')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                :placeholder="$t('contractNumber1')"
                v-model="tabrow.contractNumber"
                :label="$t('contractNumber1')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                type="text"
                :placeholder="$t('identificationDocument')"
                v-model="tabrow.identificationDocument"
                :label="$t('identificationDocument')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1 mb-1">
              <!-- <custom-input-white
                :placeholder="$t('contractNumber')"
                v-model="tabrow.contractNumber"
                :label="$t('contractNumber')"
              ></custom-input-white> -->
              <custom-date-picker
                :label="$t('contractExpiration')"
                placeholder="00.00.0000"
                v-model="tabrow.contractExpiration"
              ></custom-date-picker>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <div style="text-align: right" class="mr-2">
              <b-button
                style="margin-right: 5px"
                class="mr-2"
                variant="outline-danger"
                :disabled="isSave"
                @click="BackModal"
              >
                <b-spinner
                  style="margin-left: 20px"
                  v-if="isSave"
                  small
                ></b-spinner>
                {{ $t("back") }}
              </b-button>
              <b-button
                class="ml-2"
                variant="outline-success"
                :disabled="isSave"
                @click="AddOked"
              >
                <b-spinner
                  style="margin-left: 20px"
                  v-if="isSave"
                  small
                ></b-spinner>
                {{ $t("save") }}
              </b-button>
            </div>
          </b-row>
        </b-modal>
        <b-modal
          size="lg"
          v-model="modalAuditors"
          hide-footer
          hide-header
          no-close-on-backdrop
        >
          <b-row>
            <b-col>
              <h5>{{ $t("InvolvedAuditors") }}</h5>
            </b-col>
          </b-row>
          <b-row class="backColor">
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                :placeholder="$t('organizationNameAuitor')"
                v-model="tabrowAuditors.organizationName"
                :label="$t('organizationNameAuitor')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-date-picker
                :label="$t('licenseDate')"
                placeholder="00.00.0000"
                v-model="tabrowAuditors.licenseDate"
              ></custom-date-picker>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                type="text"
                :placeholder="$t('licenseNumber')"
                v-model="tabrowAuditors.licenseNumber"
                :label="$t('licenseNumber')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-date-picker
                :label="$t('licenseExpiration')"
                placeholder="00.00.0000"
                v-model="tabrowAuditors.licenseExpiration"
              ></custom-date-picker>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                type="text"
                :placeholder="$t('auditorFullName')"
                v-model="tabrowAuditors.auditorFullName"
                :label="$t('auditorFullName')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                type="text"
                :placeholder="$t('contractNumber')"
                v-model="tabrowAuditors.contractNumber"
                :label="$t('contractNumber')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1 mb-1">
              <custom-date-picker
                :label="$t('contractExpiration')"
                placeholder="00.00.0000"
                v-model="tabrowAuditors.contractExpiration"
              ></custom-date-picker>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <div style="text-align: right" class="mr-2">
              <b-button
                class="mr-2"
                variant="outline-danger"
                style="margin-right: 5px"
                :disabled="isSave"
                @click="modalAuditorsClose"
              >
                <b-spinner
                  style="margin-left: 20px"
                  v-if="isSave"
                  small
                ></b-spinner>
                {{ $t("back") }}
              </b-button>
              <b-button
                class="ml-2"
                variant="outline-success"
                :disabled="isSave"
                @click="AddAuditorsTable"
              >
                <b-spinner
                  style="margin-left: 20px"
                  v-if="isSave"
                  small
                ></b-spinner>
                {{ $t("save") }}
              </b-button>
            </div>
          </b-row>
        </b-modal>
        <b-modal
          size="lg"
          v-model="modalInspectors"
          hide-footer
          hide-header
          no-close-on-backdrop
        >
          <b-row>
            <b-col>
              <h5>{{ $t("involvedInspectors") }}</h5>
            </b-col>
          </b-row>
          <b-row class="backColor">
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                :placeholder="$t('organizationName')"
                v-model="tabrowInspectors.organizationName"
                :label="$t('organizationName')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                :placeholder="$t('address')"
                v-model="tabrowInspectors.address"
                :label="$t('address')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                :placeholder="$t('inspectorFullName')"
                v-model="tabrowInspectors.inspectorFullName"
                :label="$t('inspectorFullName')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                :placeholder="$t('dutyNumberInspector1')"
                v-model="tabrowInspectors.dutyNumber"
                :label="$t('dutyNumberInspector1')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-date-picker
                :label="$t('dutyExpirationInspector')"
                placeholder="00.00.0000"
                v-model="tabrowInspectors.dutyExpiration"
              ></custom-date-picker>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                type="text"
                :placeholder="$t('dutyIssuingOrganization')"
                v-model="tabrowInspectors.dutyIssuingOrganization"
                :label="$t('dutyIssuingOrganization')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                type="text"
                :placeholder="$t('certificateNumber1')"
                v-model="tabrowInspectors.certificateNumber"
                :label="$t('certificateNumber1')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1">
              <custom-input-white
                type="text"
                :placeholder="$t('certificateIssuingOrganization')"
                v-model="tabrowInspectors.certificateIssuingOrganization"
                :label="$t('certificateIssuingOrganization')"
              ></custom-input-white>
            </b-col>
            <b-col sm="12" lg="12" class="mt-1 mb-1">
              <custom-date-picker
                :label="$t('certificateExpiration')"
                placeholder="00.00.0000"
                v-model="tabrowInspectors.certificateExpiration"
              ></custom-date-picker>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <div style="text-align: right" class="mr-2">
              <b-button
                class="mr-2"
                style="margin-right: 5px"
                variant="outline-danger"
                :disabled="isSave"
                @click="modalInspectorsClose"
              >
                <b-spinner
                  style="margin-left: 20px"
                  v-if="isSave"
                  small
                ></b-spinner>
                {{ $t("back") }}
              </b-button>
              <b-button
                class="ml-2"
                variant="outline-success"
                :disabled="isSave"
                @click="AddInspectorsTable"
              >
                <b-spinner
                  style="margin-left: 20px"
                  v-if="isSave"
                  small
                ></b-spinner>
                {{ $t("save") }}
              </b-button>
            </div>
          </b-row>
        </b-modal>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import InspectionBookOfContractorService from "@/services/inspectionbookofcontractor.service";
import InspectionBookService from "@/services/inspectionbook.service";
import customDatePicker from "../../components/customDatePickerWhite.vue";
import customInput from "../../components/elements/customInput.vue";
import customInputWhite from "../../components/customInputWhite.vue";
import customSelect from "../../components/customSelectWhite.vue";
import vSelect from "vue-select";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BOverlay,
  BFormRating,
  BModal,
  BLink,
} from "bootstrap-vue";
import customTextarea from "../../components/customTextareaWhite.vue";
import InspectorService from "@/services/inspector.service";
import ControlFunctionService from "@/services/controlfunction.service";
import ManualService from "@/services/manual.service";
export default {
  data() {
    return {
      isLoading: false,
      Request: {},
      InspectionBookOfContractor: {},
      InspectionResult: {},
      RequestTable: [],
      InspectorsList: [],
      ControlFunctionList: [],
      filter: {},
      show: false,
      items: [],
      filtesIns: {
        parentOrganizationId: 0,
        organizationId: 0,
        isOnlyRegistered: true,
        isHr: false,
        isPassedAttestation: true,
        isInitQuery: true,
        sortBy: "",
        orderType: "asc",
        page: 1,
        pageSize: 2000,
      },
      modal: false,
      modalInspectors: false,
      modalAuditors: false,
      modalInspectorsInfo: false,
      filters: {
        orderedOrganizationId: 0,
      },
      isSave: false,
      tabrow: {
        id: 0,
        inspectionBookId: 0,
        fullName: "",
        contractNumber: "",
        identificationDocument: "",
        contractExpiration: "",
        // Status: 0,
      },
      tabrowInspectors: {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        address: "",
        inspectorFullName: "",
        dutyNumber: "",
        dutyExpiration: "",
        dutyIssuingOrganization: "",
        certificateNumber: "",
        certificateIssuingOrganization: "",
        certificateExpiration: "",
      },
      tabrowAuditors: {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        licenseDate: "",
        licenseNumber: "",
        licenseExpiration: "",
        auditorFullName: "",
        contractNumber: "",
        contractExpiration: "",
      },
      filter1: {
        page: 1,
        pageSize: 10,
        totalRows: 1,
        search: "",
      },
      fields: [
        {
          key: "isSelected",
          label: this.$t("isSelected"),
        },
        { key: "employeeId", label: "ID", sort: true },
        { key: "fullName", label: this.$t("fullname"), sort: true },
      ],
      editedIndex1: -1,
      editedIndex: -1,
      editedIndex2: -1,
      orgInfo: "",
      ParentList: [],
      OrganizationList: [],
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BOverlay,
    customDatePicker,
    customInput,
    BFormRating,
    customTextarea,
    vSelect,
    BModal,
    BLink,
    customInputWhite,
    customSelect,
  },
  created() {
    this.orgInfo = JSON.parse(localStorage.getItem("user_info"));
    this.Refresh();

    ManualService.GetOrganizationAsSelectList("", false, true).then((res) => {
      this.ParentList = res.data;
    });
  },
  methods: {
    ChangeOrganization() {
      this.Request.inspectionOrganizationId = 0;
      this.Request.inspectionOrganizationName = "";
      ManualService.GetOrganizationAsSelectList(
        this.Request.orderedOrganizationId,
        false,
        true
      ).then((res) => {
        this.OrganizationList = res.data;
      });
    },
    ChangeInspectionOrganization() {
      this.Request.inspectionOrganizationName = "";
      if (!!this.Request.inspectionOrganizationId) {
        this.Request.inspectionOrganizationName = this.OrganizationList.filter(
          (item) => item.value === this.Request.inspectionOrganizationId
        )[0].text;
        ControlFunctionService.GetAsSelectList(
          this.Request.orderedOrganizationId
        ).then((res) => {
          this.ControlFunctionList = res.data;
        });
      }
    },
    DeleteInspector(index) {
      this.RequestTable.splice(index, 1);
    },
    DeleteInvolvedInspector(index) {
      this.Request.involvedInspectors.splice(index, 1);
    },
    DeleteSpecialists(index) {
      this.Request.specialists.splice(index, 1);
    },
    DeleteAuditors(index) {
      this.Request.involvedAuditors.splice(index, 1);
    },
    BackModal() {
      this.modal = false;
    },
    modalInspectorsClose() {
      this.modalInspectors = false;
    },
    modalAuditorsClose() {
      this.modalAuditors = false;
    },
    modalInspectorsInfoClose() {
      this.modalInspectorsInfo = false;
    },
    AddNewInspectors() {
      this.modalInspectorsInfo = true;
      this.isLoading = true;
      InspectorService.GetAsSelectList({
        parentOrganizationId: this.Request.orderedOrganizationId,
        organizationId: this.Request.inspectionOrganizationId,
        isHr: false,
        isPassedAttestation: true,
        search: this.filter1.search,
        sortBy: this.filter1.sortBy,
        orderType: this.filter1.orderType,
        page: this.filter1.page,
        pageSize: this.filter1.pageSize,
      }).then((res) => {
        // this.items = res.data.rows;
        var ids = this.RequestTable.map((item) => item.employeeId);
        this.items = [];
        for (let i = 0; i < res.data.rows.length; i++) {
          this.items.push({
            fullName: res.data.rows[i].text,
            certificateExpiration: res.data.rows[i].expirationDate,
            certificateNumber: res.data.rows[i].certificateNumber,
            employeeId: res.data.rows[i].value,
            isSelected: ids.includes(res.data.rows[i].value),
          });
        }
        this.filter1.totalRows = res.data.total;
        // this.addInspectorList()
        this.isLoading = false;
      });
    },
    addInspectorList() {
      var ids = this.RequestTable.map((item) => item.employeeId);
      this.items = [];
      for (let i = 0; i < this.items.length; i++) {
        this.items.push({
          fullName: this.items[i].text,
          certificateExpiration: res.data.rows[i].expirationDate,
          certificateNumber: res.data.rows[i].certificateNumber,
          id: this.items[i].value,
          isSelected: ids.includes(this.items[i].value),
        });
      }
      this.filter1.totalRows = res.data.total;
    },
    AddSpecialists() {
      this.modal = true;
      this.tabrow = {
        id: 0,
        inspectionBookId: 0,
        fullName: "",
        contractNumber: "",
        identificationDocument: "",
        contractExpiration: "",
      };
    },

    AddInspectors() {
      this.modalInspectors = true;
      this.tabrowInspectors = {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        address: "",
        inspectorFullName: "",
        dutyNumber: "",
        dutyExpiration: "",
        dutyIssuingOrganization: "",
        certificateNumber: "",
        certificateIssuingOrganization: "",
        certificateExpiration: "",
      };
    },
    EditSpecialists(item) {
      this.modal = true;
      this.editedIndex1 = this.Request.specialists.indexOf(item);
      this.tabrow = Object.assign({}, item);
    },
    EditAuditors(item) {
      this.modalAuditors = true;
      this.editedIndex = this.Request.involvedAuditors.indexOf(item);
      this.tabrowAuditors = Object.assign({}, item);
    },
    // InspectorsChange() {
    //   this.Request.inspectorNames = this.filter.InspectorsList(
    //     (item) => item.value === this.Request.inspectors
    //   )[0];
    // },
    EditInvolvedInspector(item) {
      this.modalInspectors = true;
      this.editedIndex2 = this.Request.involvedInspectors.indexOf(item);
      this.tabrowInspectors = Object.assign({}, item);
    },
    ControlFunctionsChange() {
      console.log(this.Request.controlFunctions);
      if (!!this.Request.orderedOrganizationId) {
        ControlFunctionService.GetAsSelectList(
          this.Request.orderedOrganizationId
        ).then((res) => {
          this.ControlFunctionList = res.data;
        });
      }
      this.Request.controlFunctions = this.filter.ControlFunctionList(
        (item) =>
          item.value ===
          this.Request.controlFunctions.map((item) => {
            item;
          })
      )[0];
    },
    AddOked() {
      if (this.editedIndex1 > -1) {
        Object.assign(this.Request.specialists[this.editedIndex1], this.tabrow);
      } else {
        this.Request.specialists.push(this.tabrow);
      }
      this.modal = false;
      this.tabrow = {};
    },
    AddInspectorsTableInfo() {
      this.items.forEach((item) => {
        if (item.isSelected) {
          var duplicateInspector = this.RequestTable.map(
            (item) => item.employeeId
          );
          if (duplicateInspector.includes(item.employeeId)) {
            // this.makeToast(this.$t("dublicateperson"), "error");
            return false;
          } else {
            this.RequestTable.push(item);
          }
        }
      });
      this.modalInspectorsInfo = false;
    },
    AddAuditors() {
      this.modalAuditors = true;
      this.tabrowAuditors = {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        licenseDate: "",
        licenseNumber: "",
        licenseExpiration: "",
        auditorFullName: "",
        contractNumber: "",
        contractExpiration: "",
      };
    },
    AddAuditorsTable() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.Request.involvedAuditors[this.editedIndex],
          this.tabrowAuditors
        );
      } else {
        this.Request.involvedAuditors.push(this.tabrowAuditors);
      }
      this.modalAuditors = false;
      this.tabrowAuditors = {};
    },
    AddInspectorsTable() {
      if (this.editedIndex2 > -1) {
        Object.assign(
          this.Request.involvedInspectors[this.editedIndex2],
          this.tabrowInspectors
        );
      } else {
        this.Request.involvedInspectors.push(this.tabrowInspectors);
      }
      this.modalInspectors = false;
      this.tabrowInspectors = {};
    },
    Refresh() {
      this.show = true;
      InspectionBookService.Get(this.$route.params.id)
        .then((res) => {
          this.Request = res.data;
          this.RequestTable = res.data.inspectors;
          this.show = false;
          ManualService.GetOrganizationAsSelectList(
            this.Request.orderedOrganizationId,
            false,
            true
          ).then((res) => {
            this.OrganizationList = res.data;
          });
          this.ControlFunctionsChange();
          if (!!this.Request.inspectionOrganizationId) {
            this.Request.inspectionOrganizationName = this.OrganizationList.filter(
              (item) => item.value === this.Request.inspectionOrganizationId
            )[0].text;
            ControlFunctionService.GetAsSelectList(
              this.Request.orderedOrganizationId
            ).then((res) => {
              this.ControlFunctionList = res.data;
            });
          }
        })
        .catch((error) => {
          this.makeToast(error.response.data, "error");
          this.show = false;
        });
    },
    back() {
      // this.$router.push({ name : 'inspectionbookofcontractor'})
      this.$router.go(-1);
    },
    SaveData() {
      if (
        this.$route.query.contractor === false ||
        this.$route.query.contractor === "false"
      ) {
        if (
          this.Request.orderedOrganizationId === 0 ||
          this.Request.orderedOrganizationId === "" ||
          this.Request.orderedOrganizationId === undefined ||
          this.Request.orderedOrganizationId === null
        ) {
          this.makeToast(
            this.$t("orderedOrganizationNotCorrect"),
            this.$t("Error"),
            "danger"
          );
          return false;
        }
        if (
          this.Request.inspectionOrganizationId === 0 ||
          this.Request.inspectionOrganizationId === "" ||
          this.Request.inspectionOrganizationId === undefined ||
          this.Request.inspectionOrganizationId === null
        ) {
          this.makeToast(
            this.$t("inspectionOrganizationNotCorrect"),
            this.$t("Error"),
            "danger"
          );
          return false;
        }
        if (
          this.Request.startDate === 0 ||
          this.Request.startDate === "" ||
          this.Request.startDate === undefined ||
          this.Request.startDate === null
        ) {
          this.makeToast(
            this.$t("startDateNotCorrect"),
            this.$t("Error"),
            "danger"
          );
          return false;
        }
        if (
          this.Request.endDate === 0 ||
          this.Request.endDate === "" ||
          this.Request.endDate === undefined ||
          this.Request.endDate === null
        ) {
          this.makeToast(
            this.$t("endDateNotCorrect"),
            this.$t("Error"),
            "danger"
          );
          return false;
        }
        if (
          this.Request.checkDaysNumber === 0 ||
          this.Request.checkDaysNumber === "" ||
          this.Request.checkDaysNumber === undefined ||
          this.Request.checkDaysNumber === null
        ) {
          this.makeToast(
            this.$t("checkDaysNumberNotCorrect"),
            this.$t("Error"),
            "danger"
          );
          return false;
        }
        var data = {};
        data.id = this.Request.id;
        data.statusId = this.Request.statusId;
        data.docNumber = this.Request.docNumber;
        data.orderedOrganizationId = this.Request.orderedOrganizationId;
        data.inspectionOrganizationId = this.Request.inspectionOrganizationId;
        data.inspectionOrganizationAddres = this.Request.inspectionOrganizationAddres;
        data.inspectionOrganizationCeo = this.Request.inspectionOrganizationCeo;
        data.inspectionOrganizationName = this.Request.inspectionOrganizationName;
        data.criminalCaseInfo = this.Request.criminalCaseInfo;
        data.contractorAddres = this.Request.contractorAddres;
        data.orderNumber = this.Request.orderNumber;
        data.orderDate = this.Request.orderDate;
        data.orderCode = this.Request.orderCode;
        data.requestId = this.Request.requestId;
        data.docDate = this.Request.docDate;
        data.startDate = this.Request.startDate;
        data.endDate = this.Request.endDate;
        data.checkDaysNumber = this.Request.checkDaysNumber;
        data.rate = this.Request.rate;
        data.controlFunctions = this.Request.controlFunctions;
        data.inspectors = this.RequestTable;
        data.involvedAuditors = this.Request.involvedAuditors;
        data.involvedInspectors = this.Request.involvedInspectors;
        data.specialists = this.Request.specialists;
        data.comment = this.Request.comment;
        InspectionBookService.Update(data)
          .then((res) => {
            this.makeToast(this.$t("SaveSuccess"), "success");
            this.$router.push({ name: `inspectionbookofcontractor` });
          })
          .catch((error) => {
            this.makeToast(error.response.data.errors, "error");
          });
      } else if (
        this.$route.query.contractor === true ||
        this.$route.query.contractor === "true"
      ) {
        var contractorData = {};
        contractorData.id = this.Request.id;
        contractorData.orderCode = this.Request.orderCode;
        contractorData.contractorId = this.Request.contractor.id;
        contractorData.docDate = this.Request.docDate;
        contractorData.contractorStartDate = this.Request.contractorStartDate;
        contractorData.contractorEndDate = this.Request.contractorEndDate;
        contractorData.contractorCheckDaysNumber = this.Request.contractorCheckDaysNumber;
        contractorData.rate = this.Request.rate;
        contractorData.requestedDocuments = this.Request.requestedDocuments;
        contractorData.takenMeasures = this.Request.takenMeasures;
        contractorData.thirdPartyInfo = this.Request.thirdPartyInfo;
        contractorData.comment = this.Request.comment;
        console.log(contractorData);

        InspectionBookService.UpdateContractor(contractorData)
          .then((res) => {
            this.makeToast(this.$t("SaveSuccess"), "success");
            this.$router.push({ name: `inspectionbookofcontractor` });
          })
          .catch((error) => {
            this.makeToast(error.response.data.errors, "error");
          });
      }
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
  },
};
</script>

<style>
.backColor {
  background-color: #f3f1f1 !important;
}
.vs__dropdown-toggle {
  background-color: white !important;
}
.v-toast__item--Error {
  background-color: red;
}
.v-toast__item--success {
  background-color: green;
}
.cardStyleEdit {
  padding: 5px;
  background-color: #eaf2ff;
  border-radius: 5px;
}
.styleText {
  font-weight: 900;
}
.my-select .vs__dropdown-toggle {
  padding: 12px 10px !important;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #f7f7f7;
}
</style>
